<template>
  <div>
    <!-- 顶部搜索 -->
    <div class="recordBar">
      <div class="container inner">
        <a-row class="recordRow">
          <a-col
          class="recordCol"
            :md="24"
            :lg="24"
            :xl="8"
            v-for="(item,index) in list"
            :key="index"
          >
            <div class="block">
              <div class="topWrap">
                <div>
                  <h3><ICountUp
                    :delay="delay"
                    :endVal="item.title"
                  /> <span>{{item.sign}}</span></h3>
                  <p>{{item.desc}}</p>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <!--  -->
    <div class="catalogWrap container">
      <div class="container inner">
        <div class="search_div">
          <div class="record">
            <el-select
              class="leftSelect"
              v-model="value"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchValue"
              placeholder="交易hash/证书编号"
            ></el-input>
          </div>
          <a-button
            type="primary"
            size="large"
            @click="searchCertificate"
          > 搜索 </a-button>
        </div>
      </div>

      <div
        v-if="searchResultVisible"
        class="container resultView"
      >
        <!-- 证书搜索 -->
        <template v-if="resultType==1">
          <div class="commonTitle"> 搜索结果 </div>
          <div @click="resultType=2">查看上链信息</div>
          <div class="imgWrap">
            <el-image
              :src="certificateImg"
              :preview-src-list="[certificateImg]"
            >
            </el-image>
          </div>
        </template>

        <!-- hash搜索-->
        <template v-if="resultType==2">
          <div class="commonWhiteBg HashResult">
            <div class="commonTitle"> 证书详情 </div>
            <table class="detailTable">
              <tr>
                <td class="label"> 公示hash </td>
                <td class="value"> {{transactionInfo.transactionData.hash}} </td>
              </tr>
              <tr>
                <td class="label"> 公示时间 </td>
                <td class="value"> {{transactionInfo.transactionData.timeText}} </td>
              </tr>
              <tr>
                <td class="label"> 交易类型 </td>
                <td class="value"> {{transactionInfo.transactionData.txType}} </td>
              </tr>
              <tr>
                <td class="label"> 区块高度 </td>
                <td class="value"> {{transactionInfo.blockNumber}} </td>
              </tr>
              <tr>
                <td class="label"> 区块hash </td>
                <td class="value"> {{transactionInfo.blockData.blockHash}} </td>
              </tr>
              <tr>
                <td class="label"> Nonce </td>
                <td class="value"> {{transactionInfo.transactionData.nonce}} </td>
              </tr>
              <tr>
                <td class="label"> 发起账户 </td>
                <td class="value"> {{transactionInfo.transactionData.from}} </td>
              </tr>
              <tr>
                <td class="label"> 目标账户 </td>
                <td class="value"> {{transactionInfo.transactionData.to}} </td>
              </tr>
              <tr>
                <td class="label"> 交易金额 </td>
                <td class="value"> {{transactionInfo.transactionData.value}} </td>
              </tr>
              <tr>
                <td class="label"> 证书 </td>
                <td class="value toSee" @click="resultType=1"> 查看 </td>
              </tr>
              <!-- <tr>
                <td class="label"> 签名 </td>
                <td class="value">
                  <el-popover
                    placement="right"
                      width="400"
                    trigger="click"
                  >
                      <el-table
                      width="100%"
                      :data="gridData"
                    >
                      <el-table-column
                        property="code"
                        label="签名"
                      ></el-table-column>
                    </el-table>
                    <a slot="reference">详情</a>
                  </el-popover>
                </td>
              </tr>
              <tr>
                <td class="label"> 交易收据 </td>
                <td class="value">
                  <el-popover
                    placement="right"
                      width="400"
                    trigger="click"
                  >
                    <el-table
                      width="100%"
                      :data="gridData2"
                    >
                      <el-table-column
                        property="result"
                        label="result"
                      ></el-table-column>
                      <el-table-column
                        property="data"
                        label="data"
                      ></el-table-column>
                      <el-table-column
                        property="gasUsed"
                        label="gasUsed"
                      ></el-table-column>
                      <el-table-column
                        property="log"
                        label="log"
                      ></el-table-column>
                    </el-table>
                    <a slot="reference">详情</a>
                  </el-popover>
                </td>
              </tr>
              <tr>
                <td class="label"> 交易金额 </td>
                <td class="value"> 0 </td>
              </tr> -->
            </table>
          </div>
        </template>
      </div>

      <a-row v-else class="container wrapRow">
          <!-- <a-col class="row_l" :md="24" :lg="24" :xl="16" > -->
          <a-col class="row_l" :md="24" :lg="24" :xl="24" >
            <a-col class="row_li" :md="24" :lg="12" :xl="6" v-for="(item,index) in wrapRowList" :key="index">
              <div>
                <el-image :src="item.imgSrc" 
                :preview-src-list="[item.imgSrc]" alt="">
                </el-image>
                <h3>{{item.title}}</h3>
                <p>{{item.date}}</p>
              </div>
            </a-col>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pagination.total"
              :current-page.sync="pagination.page"
              :page-size.sync="pagination.pageSize"
              @size-change="sizeChange"
              @current-change="currentChange"
              :pagerCount="5"
            >
            </el-pagination>
          </a-col>
          <!-- <a-col class="row_r" :md="24" :lg="24" :xl="8" >
            <h3>今日存证</h3>
            <p v-if="wrapRowList2.length == 0">暂无数据</p>
            <ul v-else v-for="(item,index) in wrapRowList2" :key="index">
              <li>
                <span>{{item.title}}</span>
                <span>{{item.date}}</span>
              </li>
            </ul>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pagination2.total"
              :current-page.sync="pagination2.page"
              :page-size.sync="pagination2.pageSize"
              @size-change="sizeChange2"
              @current-change="currentChange2"
              :pagerCount="5"
            >
            </el-pagination>
          </a-col> -->
        </a-row>
        
    </div>

  </div>
</template>

<script>
import { queryAllExistEvidence, queryExistEvidenceSummary, queryExistEvidenceSummary2 } from "@/api/EvidenceService";
import { queryCertificateByHash,queryCertificate } from '@/api/CertificateService'
import ICountUp from 'vue-countup-v2'
export default {
  name: 'Record',
  components: {ICountUp},
  data () {
    return {
      options: [{
        value: '-1',
        label: '全部'
      },],
      value: '-1',
      input: '',
      searchResultVisible: false,
      resultType: 2,
      searchValue: this.$route.params.transactionHash||'',
      certificateInfo:null,
      transactionInfo:null,
      list:[
        {
          title:'',
          desc:'中国知链总存证数',
          // sign: '+'
        },
        {
          title:'',
          desc:'今日新增存证数',
          // sign: '+'
        },
        {
          title:'',
          desc:'交易金额总数',
          sign: '元'
        },
      ],
      delay: 1000,
      orderFilter:{
          status:null,
      },
      wrapRowList: [],
      wrapRowList2: [],
      evidenceOrderFilter:{
          subject:null,
          date:null,
          status:'21'
      },
      evidenceOrderFilter2:{
          subject:null,
          date:null,
          status:'21'
      },
      pagination: {
        page: 1,
        pageSize: 8,
        total:0
      },
      pagination2: {
        page: 1,
        pageSize: 10,
        total:0
      },
    }
  },
  created() {
    // this.list = JSON.parse(this.$route.query.list);
    this.init();
    this.fetchData();
    this.fetchData2();
  },
  methods: {
    init() {
      console.log("init result ..");
//       queryExistEvidenceSummary(this.orderFilter).then(({data})=>{
// //         console.log(data)
//         let list = this.list;
//         list[0].title=data.totalCount;
//         let todayCount=parseInt(data.todayCount);
//         if(todayCount==0){
//           todayCount = 18;
//         }
//         list[1].title=todayCount
// //         list[2].title=data.totalAmount;
//         list[2].title=data.totalCount * 188;
//       })
      queryExistEvidenceSummary2().then(({data})=>{
      //         console.log(data)
        this.list[0].title=parseInt(data.totalCount);
        this.list[1].title=parseInt(data.todayCount);
        this.list[2].title=parseInt(data.totalAmount);
      })
      if(this.searchValue){
        // this.searchValue=this.$route.params.transactionHash;
        this.searchCertificate(); 
      }
    },
    searchCertificate(){
      let that=this;
        if(this.searchValue.indexOf('-')>-1){
          //根据证书编号查询证书
          queryCertificate(this.searchValue).then(({data})=>{
              console.log(data);
              that.resultType="1";
              that.searchResultVisible=true;
              that.certificateImg=data.certificateInfo.jpgUrl;

               that.certificateInfo=data;
             that.transactionInfo=data.transactionInfo
             
             that.transactionInfo.transactionData.timeText=that.dayjs(that.transactionInfo.transactionData.timestamp).format('YYYY-MM-DD HH:mm:ss');

          });
        }else{
          //根据hash查询证书
           queryCertificateByHash(this.searchValue).then(({data})=>{
             console.log(data);
             that.resultType="2";
             that.certificateImg=data.certificateInfo.jpgUrl;

             that.certificateInfo=data;
             that.transactionInfo=data.transactionInfo
             
             that.transactionInfo.transactionData.timeText=that.dayjs(that.transactionInfo.transactionData.timestamp).format('YYYY-MM-DD HH:mm:ss');
             that.searchResultVisible=true;
          });
        }
    },
    fetchData() {
      console.log("init result ..");
      queryAllExistEvidence(this.pagination.page,this.pagination.pageSize,this.evidenceOrderFilter).then(({data})=>{
        console.log("存证列表：1111111111111",data.list)
        let list = [];
        for (let i = 0; i < data.list.length; i++) {
          // 判断是否存证成功，成功才有 certificateInfo
          if(data.list[i].certificateInfo){
            list.push({
              imgSrc: data.list[i].certificateInfo.jpgUrl,
              title: data.list[i].subject,
              date: this.dayjs(data.list[i].createTime * 1000).format("YYYY-MM-DD"),
            })
          }
        }
        this.wrapRowList = list;
        this.pagination.total=data.total*1;
      })
    },
    fetchData2() {
      // 右侧今日存证列表，需要传参当前日期
      this.evidenceOrderFilter2.date = this.dayjs(Math.round(new Date().getTime()/1000).toString() * 1000).format("YYYY-MM-DD")
      // console.log('今日日期：',this.evidenceOrderFilter2.date);
      queryAllExistEvidence(this.pagination2.page,this.pagination2.pageSize,this.evidenceOrderFilter2).then(({data})=>{
        console.log("存证列表：222222222222",data.list)
        let list = [];
        for (let i = 0; i < data.list.length; i++) {
          list.push({
            title: data.list[i].subject,
            date: this.dayjs(data.list[i].createTime * 1000).format("HH:mm:ss "),
          })
        }
        this.wrapRowList2 = list;
        this.pagination2.total=data.total*1;
      })
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },
    sizeChange2 (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData2()
    },
    currentChange2 (pageNum) {
      // 更新表格数据
      this.fetchData2()
    },
  }
}
</script>

<style lang='scss' scoped>
.HashResult {
  box-shadow: none;
  padding: 34px 36px;
  .commonTitle {
    font-size: 16px;
  }
  .detailTable {
    width: 100%;
    tr {
      border-bottom: solid 1px #eee;
      td {
        padding: 16px;
        vertical-align: top;
      }
      .label {
        width: 25%;
        color: rgba(0, 18, 22, 0.5);
      }
      .value {
        color: rgba(0, 18, 22, 1);
        white-space: normal;
        word-break: break-all;
      }
      .toSee{
        cursor: pointer;
      }
    }
  }
}
.resultView {
  margin: 32px auto;
  .imgWrap {
    display: flex;
    justify-content: center;
    /deep/ .el-image {
      width: 80%;
      max-width: 595px;
    }
  }
}
.recordBar {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-topBanner.png')
    no-repeat 50% 100%;
  background-size: 100% 100%;
  padding: 72px 0;
  /deep/ .el-input.is-active .el-input__inner,
  /deep/ .el-input__inner:focus {
    box-shadow: none;
    border: none;
  }
  .inner {
    .recordRow{
      display: block;
      display: flex;
      .topWrap{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
        text-align: center;
        text-align: left;
        h3 {
          margin-bottom: 8px;
          font-size: 26px;
          color:#fff;
        }
        p{
          color: rgba(250, 252, 255, 0.8);
        }
      }
      .recordCol:nth-child(1){
        h3{
          span{
            display: inline-block;
            font-size: 20px;
            padding-top: -50px;
          }
        }
      }
      .recordCol:nth-child(3){
        h3{
          span{
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.catalogWrap {
  margin-top: -80px;
  margin-bottom: 32px;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
  padding: 28px 0;
  color: rgba(0, 18, 22, 0.85);
  border-radius: 8px;
  position: relative;
  .ant-btn {
      margin-left: 8px;
      height: 48px;
      border-radius: 2px;
      padding: 0 21px;
    }
  .search_div{
    display: flex;
    .record {
      padding: 0;
      height: 48px;
      line-height: 48px;
      margin-bottom: 50px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #007DFF;
      flex: 1;
      display: flex;
      /deep/ .el-select .el-input.is-focus .el-input__inner,
      /deep/ .el-input__inner {
        border: none;
      }
      .leftSelect /deep/ .el-input__inner {
        text-align: center;
        color: rgba(0, 18, 22, 0.45);
      }
    }
  }
  .wrapRow{
    margin-top: 28px;
    .row_l{
      .row_li{
        padding: 0 20px 30px 20px;
        div{
          box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
          padding-bottom: 20px;
        }
        .el-image{
          box-shadow: none;
          padding-bottom: 0;
        }
        img{
          width: 100%;
        }
        h3{
          font-size: 14px;
          color: rgba(0, 18, 22, 0.85);
          line-height: 30px;
          margin: 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p{
          display: block;
          width: 100px;
          background: #F3F9FF;
          border-radius: 2px;
          padding: 0 5px;
          line-height: 24px;
          font-size: 12px;
          color: #007DFF;
          margin: 0 10px;
        }
      }
    }
    .row_r{
      h3{
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
      }
      h3::before {
        content: '';
        width: 6px;
        height: 24px;
        border-radius: 2px;
        background: #007DFF;
        position: absolute;
        left: 0;
        top: 3px;
      }
      p{
        line-height: 60px;
        text-align: center;
        color: #909399;
      }
      ul{
        li{
          color: rgba(0, 18, 22, 0.85);
          padding: 5px 0;
          line-height: 1.769230769230769;
          position: relative;
          span:nth-child(1){
            display: inline-block;
            text-indent: 2em;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }
          span:nth-child(2){
            float: right;
          }
        }
        li::before{
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #007DFF;
          position: absolute;
          left: 10px;
          top: 15px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .catalogWrap {
    flex-wrap: wrap;
  }
}
</style>
